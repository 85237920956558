import version from '@/version.json';
import heartbeatMutation from '@/graphql/mutations/heartbeat.gql';

export default function send() {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return useMutation(heartbeatMutation, {
    variables: {
      input: {
        user_id: user?.id ?? null,
        app_version: version,
        device_id: deviceId(),
        user_agent: navigator.userAgent,
      },
    },
  }).mutate();
}
