const trackMap = {
  ast1: 'lower',
  ast2: 'middle',
  ast3: 'upper',
};

export const useQuizQuiz = defineStore('quiz', () => {
  /**
   * State
   */
  // @ts-ignore
  const practiceQuestion: QuestionModel = {
    id: 'practice',
    question: 'quiz.practice.question', // Uses translation key as we cannot translate in stores.
    quiz_id: '',
    description: 'quiz.practice.description',
    ordering: 0,
    options: [
      { id: 'practice-option-1', option: 'quiz.practice.yes', ordering: 0 },
      { id: 'practice-option-2', option: 'quiz.practice.no', ordering: 1 },
    ] as OptionModel[],
  };

  /**
   * Getters
   */
  const all = () => database.quiz_quizzes.toArray();
  const byBuild = (school: Build) =>
    database.quiz_quizzes.where('school').equals(school).first();
  const byTrack = (track: Track) =>
    database.quiz_quizzes.where('school').equals(trackMap[track]).first();
  const byId = (id: string) => database.quiz_quizzes.get(id);

  // Actions
  const sync = async (newQuizzes: QuizModel[]) => {
    const quizIds = newQuizzes.map((quiz: QuizModel) => quiz.id);
    newQuizzes = newQuizzes.map((quiz: QuizModel) => ({
      ...quiz,
      school: quiz.school.toLowerCase(),
    }));

    database.transaction('rw', [database.quiz_quizzes], async () => {
      // Insert new quizzes.
      await database.quiz_quizzes.bulkPut(newQuizzes);

      // Remove quizzes that are not in the current list.
      await database.quiz_quizzes.where('id').noneOf(quizIds).delete();
    });
  };

  return {
    // State
    practiceQuestion,

    // Getters
    all,
    byId,
    byBuild,
    byTrack,

    // Actions
    sync,
  };
});
