import syncQuery from '@/graphql/queries/sync.gql';

export const useSync = defineStore('sync', () => {
  const sync = async () => {
    // Execute the query and store the data.
    try {
      const { data } = await useAsyncQuery(syncQuery);
      await useAlert().sync(data.value.alerts);
      useUser().setUser(data.value.user);
      await useQuizQuiz().sync(data.value.quizzes);

      // Sync the submissions.
      await Promise.all(
        data.value.submissions.map(async (model: Submission) =>
          !model.deleted_at
            ? await useQuizSubmission().store(model)
            : await useQuizSubmission().remove(model.id),
        ),
      );

      // Sync the teams, groups, children and tests.
      const schoolIds: string[] = [];
      const groupIds: string[] = [];
      const childIds: string[] = [];
      const testIds: string[] = [];

      // Fill the stores.
      await Promise.all(
        data.value.schools.map(async (model: SchoolModel) => {
          const school = JSON.parse(JSON.stringify(model)) as SchoolModel;

          school?.groups?.forEach(async (group: GroupModel) => {
            group?.children?.forEach(async (child: ChildModel) => {
              child?.tests?.forEach(async (original) => {
                testIds.push(original.id);
                await database.tests.put(original);
              });

              childIds.push(child.id);
              delete child.tests;
              await database.children.put(
                Object.assign(child, { group_id: group.id }),
              );
            });

            // Remove unwanted data and store.
            groupIds.push(group.id);
            delete group.children;
            await database.groups.put(group);
          });

          // Remove unwanted data.
          delete school.groups;

          await database.schools.put(school);
          schoolIds.push(school.id);
        }),
      );

      // Remove any assets not present in the response.
      Promise.all([
        database.schools.where('id').noneOf(schoolIds).delete(),
        database.groups.where('id').noneOf(groupIds).delete(),
        database.children.where('id').noneOf(childIds).delete(),
        database.tests.where('id').noneOf(testIds).delete(),
      ]).then(() => {
        log('sync.schools', 'Successfully synced schools.');
      });
    } catch (e) {
      useUser().fetch();
    }
  };

  return {
    sync,
  };
});
