export const useSchool = defineStore('school', () => {
  // Getters
  const all = async () => await database.schools.toArray();
  const byId = async (id: string) => await database.schools.get(id);
  const byIds = async (ids: string[]) => await database.schools.bulkGet(ids);
  const hasLifestyleAccess = async (id: string) =>
    (await byId(id))?.lifestyle_enabled ?? false;

  // Actions
  const put = async (school: SchoolModel) => await database.schools.put(school);

  return {
    // Getters
    all,
    byId,
    byIds,
    hasLifestyleAccess,

    // Actions
    put,
  };
});
