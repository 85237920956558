import { consola } from 'consola';
import version from '../version.json';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  // Do not initialize Sentry in development mode.
  if (import.meta.env.DEV) return;

  Sentry.init({
    release: version,
    app: nuxtApp.vueApp,
    environment: import.meta.env.VITE_ENV,
    dsn: useRuntimeConfig().public.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.25,
    ignoreErrors: [
      'Failed to update a ServiceWorker for scope',
      'ResizeObserver loop limit exceeded',
      'Failed to fetch dynamically imported module',
      'newestWorker is null',
    ],
  });

  consola.success('[system] Sentry initialized.');
});
