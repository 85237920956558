import { sync as syncLog } from '@/utils/logging';

export const useQueue = defineStore('queue', () => {
  /**
   * State
   */
  const pending: Ref<number> = ref(0);

  /**
   * Getters
   */
  const byType = (type: QueueItem['type']) =>
    database.queue.where('type').equals(type).toArray();

  /**
   * Actions
   */
  const add = (item: QueueItem) => database.queue.put(item);
  const remove = (item: QueueItem) =>
    database.queue.where({ id: item.id, type: item.type }).delete();
  const sync = async () => {
    // Sync with the data from the server.
    // log('sync.queue', 'Syncing data from server.');
    await syncLog();
    // await useQuizSubmission().sync();

    // Sync the queue.
    // log('sync.queue', 'Syncing queue.');

    const items = await database.queue.toArray();
    pending.value = items.length;

    items.forEach(async (item) => {
      switch (item.type) {
        case 'scan_test':
          await useScanTest()
            .sync(item.id)
            .then(async () => {
              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing test.',
                { item, error },
                'error',
              );
            });
          break;
        case 'scan_swap':
          await useScanTest()
            .syncSwap(item.payload?.first_id, item.payload?.second_id)
            .then(async () => {
              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing test swap.',
                { item, error },
                'error',
              );
            });
          break;
        case 'quiz_submission':
          await useQuizSubmission()
            .push(item.id)
            .then(async () => {
              log('sync.queue', 'Removed item from queue.', { item });
              await remove(item);
              pending.value -= 1;
            })
            .catch((error) => {
              log(
                'sync.queue',
                'Error syncing quiz submission.',
                {
                  item,
                  error,
                },
                'error',
              );
            });
          break;
      }
    });

    // log('sync.queue', 'Finished', null, 'success');
  };

  return {
    // State
    pending,

    // Getters
    byType,

    // Actions
    add,
    sync,
    remove,
  };
});
