import { RouterConfig } from 'nuxt/schema';

export default <RouterConfig>{
  routes: () => [
    /**
     * Authentication
     */
    {
      path: '/inloggen',
      name: 'auth.login',
      component: () => import('@/pages/auth/login.vue'),
      meta: { layout: 'auth', background: 3, middleware: 'guest' },
    },

    /**
     * Application routes.
     */
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/index.vue'),
      meta: { layout: 'default', middleware: 'auth', background: 1 },
    },
    {
      path: '/over-ons',
      name: 'about',
      component: () => import('@/pages/about.vue'),
      meta: { layout: 'default', middleware: 'auth' },
    },
    {
      path: '/veelgestelde-vragen',
      name: 'faq',
      component: () => import('@/pages/faq.vue'),
      meta: { layout: 'default', middleware: 'auth' },
    },
    {
      path: '/instellingen',
      name: 'settings',
      component: () => import('@/pages/settings.vue'),
      meta: { layout: 'default', middleware: 'auth', background: 2 },
    },
    {
      path: '/systeem',
      name: 'system',
      component: () => import('@/pages/system.vue'),
      meta: { layout: 'default', middleware: 'auth', background: 2 },
    },

    /**
     * Scan routes.
     */
    {
      path: '/scan',
      name: 'scan.home',
      component: () => import('@/pages/scan/home.vue'),
      meta: { layout: 'default', middleware: 'auth' },
    },
    {
      path: '/scan/onboarding/:id',
      name: 'scan.onboarding',
      component: () => import('@/pages/scan/onboarding.vue'),
      meta: { layout: 'auth', background: 3 },
    },
    {
      path: '/scan/voorbereiden/:school(onderbouw|middenbouw|bovenbouw)?',
      name: 'scan.prepare',
      component: () => import('@/pages/scan/prepare.vue'),
      meta: { layout: 'default', middleware: 'auth' },
    },
    {
      path: '/scan/scan-afmaken',
      name: 'scan.resume',
      component: () => import('@/pages/scan/resume.vue'),
      meta: { layout: 'default', middleware: 'auth', background: 1 },
    },
    {
      path: '/scan/scannen/:schoolId?/:groupId?',
      name: 'scan.select',
      component: () => import('@/pages/scan/select.vue'),
      meta: { layout: 'scan', middleware: 'auth' },
    },
    {
      path: '/scan/sessie/:schoolId/:groupId/:childId?',
      name: 'scan.scan',
      component: () => import('@/pages/scan/scan.vue'),
      meta: { layout: 'scan', middleware: 'auth' },
    },
    {
      path: '/scan/afronden/:schoolId/:groupId',
      name: 'scan.finish',
      component: () => import('@/pages/scan/finish.vue'),
      meta: { layout: 'scan', middleware: 'auth' },
    },

    /**
     * Quiz routes.
     */
    {
      path: '/quiz',
      name: 'quiz.home',
      component: () => import('@/pages/quiz/home.vue'),
      meta: { layout: 'quiz', middleware: 'auth' },
    },
    {
      path: '/quiz/onboarding',
      name: 'quiz.onboarding',
      component: () => import('@/pages/quiz/onboarding.vue'),
      meta: { layout: 'auth' },
    },
    {
      path: '/quiz/voorbereiden/:build(onderbouw|middenbouw|bovenbouw)?',
      name: 'quiz.prepare',
      component: () => import('@/pages/quiz/prepare.vue'),
      meta: { layout: 'quiz', middleware: 'auth' },
    },

    // Quiz
    {
      path: '/quiz/beginnen/:schoolId?/:groupId?/:step(afwezigheid|teams|beginnen)?',
      name: 'quiz.select',
      component: () => import('@/pages/quiz/select.vue'),
      meta: { layout: 'quiz', middleware: 'auth' },
    },
    {
      path: '/quiz/q/teams-maken/:submissionId/:step(grootte|aanpassen)?',
      name: 'quiz.create-teams',
      component: () => import('@/pages/quiz/quiz/1.create-teams.vue'),
      meta: {
        layout: 'quiz',
        middleware: ['auth', 'quiz-lifestyle-access', 'quiz-quiz-exists'],
      },
    },
    {
      path: '/quiz/q/teams/:submissionId',
      name: 'quiz.teams',
      component: () => import('@/pages/quiz/quiz/2.teams.vue'),
      meta: {
        layout: 'quiz',
        middleware: [
          'auth',
          'quiz-has-teams',
          'quiz-lifestyle-access',
          'quiz-quiz-exists',
        ],
      },
    },
    {
      path: '/quiz/q/sessie/:submissionId/:teamId/:step(afwezigheid|quiz|klaar)?/:questionId?/:view(vraag|antwoord)?',
      name: 'quiz.session',
      component: () => import('@/pages/quiz/quiz/3.session.vue'),
      meta: {
        layout: 'quiz',
        middleware: [
          'auth',
          'quiz-has-teams',
          'quiz-lifestyle-access',
          'quiz-team-unfinished',
          'quiz-quiz-exists',
        ],
      },
    },
    {
      path: '/quiz/q/teams/:submissionId/versturen',
      name: 'quiz.results',
      component: () => import('@/pages/quiz/quiz/4.results.vue'),
      meta: {
        layout: 'quiz',
        middleware: [
          'auth',
          'quiz-has-teams',
          'quiz-lifestyle-access',
          'quiz-quiz-exists',
        ],
      },
    },

    // Form
    {
      path: '/quiz/f/beginnen/:schoolId/:groupId',
      name: 'form.start',
      component: () => import('@/pages/quiz/form/1.start.vue'),
      meta: { layout: 'quiz', middleware: ['auth'] },
    },
    {
      path: '/quiz/f/sessie/:submissionId/:childId/:step(volgende|sessie)/:question(\\d+)?',
      name: 'form.session',
      component: () => import('@/pages/quiz/form/2.session.vue'),
      meta: { layout: 'quiz', middleware: ['auth', 'quiz-quiz-exists'] },
    },
    {
      path: '/quiz/f/sessie/:submissionId/klaar',
      name: 'form.finish',
      component: () => import('@/pages/quiz/form/3.finish.vue'),
      meta: { layout: 'quiz', middleware: ['auth', 'quiz-quiz-exists'] },
    },
  ],
};
