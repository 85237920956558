import { v4 } from 'uuid';
import swapTestsMutation from '@/graphql/mutations/swapTests.gql';
import createTestMutation from '@/graphql/mutations/createTest.gql';

export const useScanTest = defineStore('tests', () => {
  // State
  const pendingTestCount: Ref<number> = ref(0);

  // Getters
  const byId = async (id: string): Promise<TestModel | undefined> =>
    await database.tests.where('id').equals(id).last();
  const byIds = async (ids: string[]): Promise<TestModel[]> =>
    await database.tests.where('id').anyOf(ids).toArray();
  const byChildId = async (childId: string): Promise<TestModel | undefined> =>
    await database.tests.where('child_id').equals(childId).last();
  const byGroupId = async (groupId: string): Promise<TestModel[]> =>
    await database.tests.where('group_id').equals(groupId).toArray();
  const byGroupIdAndRound = async (
    groupId: string,
    round: 1 | 2,
  ): Promise<TestModel[]> =>
    await database.tests
      .where(['group_id', 'session'])
      .equals([groupId, round])
      .toArray();
  const byChildIdAndTrack = async (
    childId: string,
    track: string,
  ): Promise<TestModel | undefined> =>
    await database.tests
      .where(['child_id', 'track'])
      .equals([childId, track])
      .last();
  const byChildIdAndSession = async (
    childId: string,
    session: 1 | 2,
  ): Promise<TestModel | undefined> =>
    await database.tests
      .where(['child_id', 'session'])
      .equals([childId, session])
      .last();
  const byChildIds = async (childIds: string[]): Promise<TestModel[]> =>
    await database.tests.where('child_id').anyOf(childIds).toArray();
  const pendingTests = (): Promise<QueueItem[]> =>
    useQueue().byType('scan_test');
  const status = async (childId: string, session: 1 | 2): Promise<boolean> =>
    (await database.tests
      .where(['child_id', 'session'])
      .equals([childId, session])
      .count()) > 0;

  // Actions
  const put = async (test: TestModel) => await database.tests.put(test);
  const add = async (test: TestModel) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!test.hasOwnProperty('id')) {
      test.id = v4();
    }

    await database.tests.put(test);
    await database.queue.put({ id: test.id, type: 'scan_test' });
  };
  const remove = async (id: string) => await database.tests.delete(id);

  /**
   * Switch two results.
   *
   * @param  {Object} firstTest The first test.
   * @param  {Object} secondTest The second test.
   * @return Promise<void>
   */
  const swapTests = async (firstTest: TestModel, secondTest: TestModel) => {
    database.transaction('rw', ['tests', 'queue'], async () => {
      // Swap the tests locally first.
      database.tests.update(firstTest.id, { child_id: secondTest.child_id });
      database.tests.update(secondTest.id, { child_id: firstTest.child_id });

      // Queue the swap request.
      useQueue().add({
        id: v4(),
        type: 'scan_swap',
        payload: {
          first_id: firstTest.id,
          second_id: secondTest.id,
        },
      });

      console.log('swapped', firstTest.id, secondTest.id);
    });
  };

  const sync = async (id: string) => {
    let test = await database.tests.where('id').equals(id).first();
    if (!test) return;

    // Replace the id with an uuid if it's a number.
    if (typeof test.id === 'number') {
      test = await swapId(test.id, v4());
    }

    return useMutation(createTestMutation, {
      variables: {
        input: {
          id: test.id,
          child_id: test.child_id,
          group_id: test.group_id,
          note: test.note,
          session: test.session,
          time: test.time,
          tested_at: test.tested_at,
          weaknesses: test.weaknesses,
          explanations: test.explanations,
        },
      },
    })
      .mutate()
      .then(async (response) => {
        if (!response?.data?.createTest) return;

        // Store the permanent test result.
        database.tests.put(response.data.createTest);
        log('sync.tests', 'Test synced:', { id: response.data.createTest.id });
      })
      .catch((error) => {
        log('sync.tests', 'Error during sync:', { error, test }, 'warning');
        throw error;
      });
  };

  const syncSwap = async (firstId: string, secondId: string) => {
    return useMutation(swapTestsMutation, {
      variables: {
        input: {
          first_id: firstId,
          second_id: secondId,
        },
      },
    })
      .mutate()
      .then(async (response) => {
        if (!response?.data?.swapTests) return;

        log('sync.tests-swapped', 'Test swapped:', {
          first_id: response.data.swapTests[0].id,
          second_id: response.data.swapTests[1].id,
        });
      })
      .catch((error) => {
        log('sync.tests', 'Error during test swap:', { error }, 'warning');
        throw error;
      });
  };

  /**
   * Download the local data.
   *
   * @return Promise<string | null>
   */
  const download = async (): Promise<string | void> => {
    let csvContent = '';

    (await database.tests.toArray()).forEach((test) => {
      const data = Object.values(test);
      csvContent += data.join(',') + '\n';
    });

    function iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }

    if (iOS()) {
      return csvContent;
    }

    // Create a blob.
    const blob = new Blob([csvContent], { type: 'text/csv;charset=UTF-8' });
    const url = window.URL.createObjectURL(blob);

    // Create a link to download the data.
    const link: HTMLElement = document.createElement('a');
    link.setAttribute('style', 'display: none');

    // Retrieve all school names.
    const name = (await useSchool().all())
      .map((school) => school.name)
      .join('--')
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${name}.csv`);

    // Append and click the link.
    document.body.appendChild(link); // Required for Firefox.
    link.click();
    document.body.removeChild(link);

    return;
  };

  /**
   * Swap the id of a test.
   *
   * @param  string|number oldId The old current id.
   * @param  string        newId The new id.
   * @return Promise<void>
   */
  const swapId = async (
    oldId: string | number,
    newId: string,
  ): Promise<TestModel> => {
    console.group('Swapping id', oldId, 'for', newId);

    const test = await byId(oldId as string);
    if (!test) throw new Error('Test not found.');

    test.id = newId;

    await database.transaction('rw', ['tests', 'queue'], async () => {
      // Store the "new" test and remove the old one.
      await put(test);
      await remove(oldId as string);

      console.log('Swapped tests successfully.');

      // If the test is in the queue, update it.
      const inQueue = await database.queue.where('id').equals(oldId).first();

      if (inQueue) {
        console.log('Updating queue item', inQueue);
        await database.queue.put({ id: newId, type: 'scan_test' });
        await database.queue.delete(oldId as string);
      }

      console.log('Test swapped from', oldId, 'to', newId);
      console.groupEnd();
    });

    return test;
  };

  return {
    // State
    pendingTestCount,

    // Getters
    byIds,
    byChildId,
    byGroupId,
    byGroupIdAndRound,
    byChildIdAndTrack,
    byChildIdAndSession,
    byChildIds,
    pendingTests,
    status,

    // Actions
    put,
    add,
    sync,
    remove,
    syncSwap,
    download,
    swapTests,
  };
});
