import { consola } from 'consola';
import loginMutation from '@/graphql/mutations/login.gql';
import logoutMutation from '@/graphql/mutations/logout.gql';

export const useUser = defineStore('user', () => {
  /**
   * State
   */
  const fetched: Ref<boolean> = ref(false);
  const user: Ref<UserModel | null> = ref(null);
  const token: Ref<string | null> = ref(localStorage.getItem('token'));

  /**
   * Getters
   */
  const loggedIn = computed(
    () =>
      // eslint-disable-next-line
    !!user.value?.hasOwnProperty('first_name') && !!user.value?.hasOwnProperty('id')
  );
  const hasLifestyleAccess = async () =>
    (await useSchool().all()).filter(
      (school: SchoolModel) => school.lifestyle_enabled,
    ).length > 0;

  /**
   * Actions
   */
  const fetch = async () => {
    return new Promise((resolve) => {
      try {
        user.value = JSON.parse(localStorage.getItem('user') || '');
      } catch {
        user.value = null;
      }

      fetched.value = true;
      resolve(user.value);
    });
  };
  const login = async (email: string, password: string) => {
    // Attempt to authenticate.
    const response = (await useMutation(loginMutation, {
      variables: {
        email,
        password,
      },
    }).mutate()) as { data: { login: string } };

    // Store the user in the state.
    token.value = response.data.login as string;

    // Store the token for Apollo.
    const { onLogin } = useApollo();
    await onLogin(token.value);

    // Sync the system.
    await useSync().sync();

    log('user.login', `Logged in as ${user.value?.first_name}.`);

    // Wait a second to allow the session to be correctly set.
    await new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const logout = async () => {
    // Try to logout on the server.
    try {
      await useMutation(logoutMutation).mutate();
    } catch (e) {
      //
    }

    // Remove the cached user.
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    user.value = null;

    // Logout of Apollo too.
    const { onLogout } = useApollo();
    await onLogout();

    // Clear the database and stores of data, except for tests, quiz data and the queue.
    database.tables
      .filter(
        (table) =>
          ![
            'tests',
            'quiz_submissions',
            'quiz_answers',
            'queue',
            'alerts',
          ].includes(table.name),
      )
      .forEach((table) => table.clear());

    log('user.logout', 'Logged out. All data has been cleared.');
  };
  const setOnboarded = async (module: 'quiz' | 'scan', onboarded = true) =>
    new Promise<void>((resolve) => {
      localStorage.setItem(`onboarded_${module}`, onboarded.toString());
      resolve();
    });
  const scanOnboarded = () => !!localStorage.getItem('onboarded_scan');
  const quizOnboarded = () => !!localStorage.getItem('onboarded_quiz');
  const setUser = (newUser: UserModel) => (user.value = newUser);

  // Event listeners.
  watch(user, (user) => {
    user
      ? localStorage.setItem('user', JSON.stringify(user))
      : localStorage.removeItem('user');
    consola.info('[store.user] User changed.', user);
  });
  watch(token, (token) => {
    token
      ? localStorage.setItem('token', token)
      : localStorage.removeItem('token');

    consola.info('[store.user] Token changed', token);
  });

  return {
    // State
    fetched,
    user,
    token,

    // Getters
    loggedIn,
    hasLifestyleAccess,

    // Actions
    fetch,
    login,
    logout,
    setUser,
    setOnboarded,
    scanOnboarded,
    quizOnboarded,
  };
});
