import { consola } from 'consola';
import send from '@/utils/heartbeat';

export default defineNuxtPlugin(async () => {
  consola.info('[system] Initializing...');

  // Send heartbeats every 5 minutes.
  send();
  setInterval(send, 5 * 60 * 1000);

  // Sync the system.
  await useSync().sync();

  // Sync the queue.
  useQueue().sync();
  setInterval(() => useQueue().sync(), 5000);

  consola.success('[system] Ready for takeoff!');
});
