import { default as aboutvsxRHOXbjhMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/about.vue?macro=true";
import { default as loginiBin6SDGGbMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/auth/login.vue?macro=true";
import { default as faqH7pxteH5yaMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/faq.vue?macro=true";
import { default as indexHMWIfv9czRMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/index.vue?macro=true";
import { default as _1_46starttiFywnc9GwMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionhBJhbt8HHUMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishLEa2UvBSzmMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/3.finish.vue?macro=true";
import { default as formnlVdJxX3FpMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form.vue?macro=true";
import { default as homeZ94WA7OcWmMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/home.vue?macro=true";
import { default as onboardingvzwe21FPgNMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/onboarding.vue?macro=true";
import { default as prepareKHHlyjCCLwMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsza7e28SjrwMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsKl6Lu03YafMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionStSIziFbWAMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46resultss8qpp2eherMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectVDIWVMpYCtMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/select.vue?macro=true";
import { default as finisheSW8tXQvyXMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/finish.vue?macro=true";
import { default as home8hxzV07fl0Meta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/home.vue?macro=true";
import { default as onboardingYTYhfkbeGTMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/onboarding.vue?macro=true";
import { default as preparexYonEWn9QcMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/prepare.vue?macro=true";
import { default as resume0GkuBRluH6Meta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/resume.vue?macro=true";
import { default as scanLlKhbc8qYWMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/scan.vue?macro=true";
import { default as selectkNdNEIlzWJMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/select.vue?macro=true";
import { default as settingspEykmf4tEGMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/settings.vue?macro=true";
import { default as systemKitJK5dVLYMeta } from "/home/forge/app.hd-institute.com/releases/20240814140603/pages/system.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutvsxRHOXbjhMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginiBin6SDGGbMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexHMWIfv9czRMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form",
    path: "/quiz/form",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form.vue").then(m => m.default || m),
    children: [
  {
    name: "quiz-form-1.start",
    path: "1.start",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-2.session",
    path: "2.session",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-3.finish",
    path: "3.finish",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz-home",
    path: "/quiz/home",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: "quiz-onboarding",
    path: "/quiz/onboarding",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "quiz-prepare",
    path: "/quiz/prepare",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-1.create-teams",
    path: "/quiz/quiz/1.create-teams",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-2.teams",
    path: "/quiz/quiz/2.teams",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-3.session",
    path: "/quiz/quiz/3.session",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-4.results",
    path: "/quiz/quiz/4.results",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: "quiz-select",
    path: "/quiz/select",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: "scan-finish",
    path: "/scan/finish",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: "scan.home",
    path: "/scan/home",
    meta: home8hxzV07fl0Meta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: "scan-onboarding",
    path: "/scan/onboarding",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "scan.prepare",
    path: "/scan/prepare",
    meta: preparexYonEWn9QcMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: "scan.resume",
    path: "/scan/resume",
    meta: resume0GkuBRluH6Meta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: "scan-scan",
    path: "/scan/scan",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: "scan-select",
    path: "/scan/select",
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingspEykmf4tEGMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: systemKitJK5dVLYMeta || {},
    component: () => import("/home/forge/app.hd-institute.com/releases/20240814140603/pages/system.vue").then(m => m.default || m)
  }
]