<script setup lang="ts">
defineProps<{ error: { statusCode: number } }>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const code = computed(() => +route.params.code);
</script>

<template>
  <div class="flex h-screen flex-grow flex-col items-center justify-center">
    <div class="space-y-3 text-center">
      <img alt="MQ Logo" src="/images/mq-logo.svg" class="mx-auto h-24 w-24" />

      <h1 class="text-3xl font-bold">
        {{ t('error.title', { code: error.statusCode }) }}
      </h1>

      <p v-if="code === 404">
        {{ t('error.404') }}
      </p>

      <div class="flex justify-center">
        <button
          @click="router.push({ name: 'home' })"
          class="flex items-center justify-center gap-1 font-medium text-orange-600"
        >
          {{ t('error.go-back') }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill="currentColor"
            class="h-4 w-4"
          >
            <path
              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
