import { liveQuery } from 'dexie';
import { useObservable, from } from '@vueuse/rxjs';
import approveGroupMutation from '@/graphql/mutations/approveGroup.gql';

export const useGroup = defineStore('group', () => {
  // State
  const groups: Ref<GroupModel[]> = useObservable(
    from(
      liveQuery(async (): Promise<unknown> => await database.groups.toArray()),
    ),
  ) as Ref<GroupModel[]>;

  // Getters
  const all = async () => await database.groups.toArray();
  const byId = async (id: string) => await database.groups.get(id);
  const byIds = async (ids: string[]) => await database.groups.bulkGet(ids);
  const bySchoolId = async (schoolId: string) =>
    await database.groups.where('school_id').equals(schoolId).toArray();
  const status = async (
    group: GroupModel,
    testSession: 1 | 2,
  ): Promise<'open' | 'pending' | 'finished'> => {
    if (group[`round_${testSession}_approved`]) return 'finished';
    if (
      // The group is fully scanned already.
      group[`round_${testSession}_scanned`] ||
      // The group has at least one test.
      (await useScanTest().byGroupIdAndRound(group.id, testSession)).length > 0
    )
      return 'pending';

    return 'open';
  };
  const session = async (groupId: string): Promise<1 | 2 | null> => {
    // Retrieve the group and school.
    const group = await byId(groupId);
    if (!group) return null;
    const school = await useSchool().byId(group.school_id);
    if (!school) return null;

    // First round.
    if (school.current_round === 1) {
      return group.round_1_approved ? 2 : 1;
    }

    // This is automatically 2 because it doesnt matter if the group is approved or not.
    // The round-number is 2.
    return 2;
  };
  const scannable = async (groupId: string): Promise<boolean> => {
    const group = await byId(groupId);
    if (!group) return false;

    const school = await useSchool().byId(group.school_id);
    if (!school) return false;

    // First scan round.
    if (school.current_round === 1) {
      return !group.round_1_approved && !school.round_1_approved;
    }

    // Second scan round.
    return !group.round_2_approved && !school.round_2_approved;
  };

  // Actions
  const put = async (group: GroupModel) => await database.groups.put(group);
  const approve = async (groupId: string, testSession: 1 | 2) => {
    const update = {};
    // @ts-ignore
    update[`round_${testSession}_approved`] = true;

    await useMutation(approveGroupMutation).mutate({
      group_id: groupId,
      session: testSession,
    });
    await database.groups.update(groupId, update);
  };

  return {
    // State
    groups,

    // Getters
    all,
    byId,
    byIds,
    status,
    session,
    scannable,
    bySchoolId,

    // Actions
    put,
    approve,
  };
});
